import Storage from './Storage';
import log from './logger';

const getCachedSegments = () => {
  let segmentIds = [];

  const rawSegmentIds = Storage.get(CXENSE_SEGMENTS_KEY, 'local');
  if (rawSegmentIds !== null) {
    segmentIds = JSON.parse(rawSegmentIds);
  } else {
    log('Segments not found in storage');
  }

  return segmentIds;
};

export default getCachedSegments;
