/**
 * @module helpers/sortQueue
 * This returns a sorted array to make the callqueue has the correct order
 * @param {array} queue
 * @returns {array} sortedQueue
 */

const sortQueue = queue => {
  const events = [];
  const initializePage = [];
  const setEventAttributes = [];
  const otherCalls = [];
  let sortedQueue = [];

  queue.forEach(element => {
    if (/sendEvent/.test(element)) {
      events.push(element);
    } else if (/initializePage/.test(element)) {
      initializePage.push(element);
    } else if (/setEventAttributes/.test(element)) {
      setEventAttributes.push(element);
    } else {
      otherCalls.push(element);
    }
  });

  sortedQueue = sortedQueue.concat(setEventAttributes);
  sortedQueue = sortedQueue.concat(initializePage);
  sortedQueue = sortedQueue.concat(otherCalls);
  sortedQueue = sortedQueue.concat(events);

  // add the push method from cx.js
  sortedQueue.push = queue.push || {};

  return sortedQueue;
};

export default sortQueue;
