import createScript from './helpers/createScript';
import getValueFromObjectList from './helpers/getValueFromObjectList';
import getAllKeyValues from './helpers/getAllKeyValues';
import getLangIdFromUrl from './helpers/getLangIdFromUrl';
import getRegioFromCookie from './helpers/getRegioFromCookie';
import sortQueue from './helpers/sortQueue';
import getCachedSegments from './helpers/getCachedSegments';
import trackImaEvents from './adapters/trackImaEvents';
import funnelEvents from './adapters/funnelEvents';
import intentEvents from './adapters/intentEvents';
import nativeArticleReads from './adapters/nativeArticleReads';
import Sync from './helpers/Sync';
import Storage from './helpers/Storage';
import UserSync from './adapters/UserSync';
import clean from './helpers/clean';
import kantar from './adapters/kantar';
import log from './helpers/logger';

const init = () => {
  // consent is not required if not defined in settings
  let consent = { value: true };
  if (CONSENT) consent = getValueFromObjectList(CONSENT);

  if (consent.value) {
    const CX_URL = 'https://cdn.cxense.com/cx.js';
    let siteid = CXENSE_SITE_ID;
    window.cX = window.cX || {};
    // export these functions for Zimmo
    window.cX.getLocalSegments = getCachedSegments;
    window.cX.getLocalEnreachId = () =>
      Storage.get(CXENSE_GLOBAL_USERID_KEY, 'local');

    if (MULTI_LANG) siteid = getLangIdFromUrl(siteid);

    const extid = getValueFromObjectList(USER);

    createScript(CX_URL);
    window.cX.shouldPoll = false;
    window.cX.callQueue = window.cX.callQueue || [];
    window.cX.callQueue.push(['initializePage']);
    if (PERSIST) {
      const s = new Sync('cxPrnd', 'cx', 'ga');
      window.cX.callQueue.push(['setRandomId', s.fetch]);
    }
    if (REGIO) {
      // regio box on popular MH titles
      const pc = getRegioFromCookie();
      if (pc) {
        window.cX.callQueue.push([
          'setUserProfileParameters',
          { regiobox: [pc] },
        ]);
      }
    }
    window.cX.callQueue.push(['setSiteId', siteid]);
    if (extid && extid.value) {
      window.cX.callQueue.push([
        'invoke',
        () => {
          window.cX.addExternalId({
            id: clean(extid.value),
            type: PREFIX,
          });
        },
      ]);
      log(`push externalId ${extid.value} through API`);
    }

    if (KNT) {
      // NLprofiel
      if (KNT.path && KNT.path.test(document.location.pathname)) {
        kantar();
      }
    }

    if (CUSTOM) {
      // Collect additional datalayer parameters
      const cp = getAllKeyValues(CUSTOM);
      if (cp) {
        window.cX.callQueue.push(['setCustomParameters', cp]);
      }
    }

    if (SHOP)
      // webshop events
      funnelEvents();

    if (INTENT)
      // intent events (Audience Interest Group)
      intentEvents();

    if (TRACKIMA)
      // video player events
      trackImaEvents();

    if (ARTICLE_READ)
      // native article reads
      nativeArticleReads();

    if (PERSIST) {
      const PERSIST_CACHE = 'adv_cache';

      if (!Storage.get(PERSIST_CACHE, 'session')) {
        // register global userid in localStorage
        try {
          const ck = getValueFromObjectList(ENREACH_KEY);
          Storage.set(CXENSE_GLOBAL_USERID_KEY, ck.value, 'local');
          // use cache flag
          Storage.set(PERSIST_CACHE, 1, 'session');
        } catch (err) {
          log(err);
        }

        window.cX.callQueue.push([
          'invoke',
          () => {
            // register userid in localStorage
            const uid = window.cX.getUserId();
            Storage.set(CXENSE_SESSION_USERID_KEY, uid, 'local');

            // register segmentids in localStorage
            try {
              window.cX.getUserSegmentIds({
                persistedQueryId: CXENSE_QUERYID,
                maxAge: 2 * 60, // seconds; default = 5 * 60
                callback: userSegmentIds => {
                  Storage.set(
                    CXENSE_SEGMENTS_KEY,
                    JSON.stringify(userSegmentIds),
                    'local'
                  );
                },
              });
            } catch (err) {
              log(`unable to get userSegmentIds from API`);
              log(err);
            }
          },
        ]);

        if (SYNC) {
          const u = new UserSync(SYNC);
          u.sendRequests();
        }
      }
    }

    if (ENREACH_URL) {
      createScript(ENREACH_URL);
    }

    // Send Page View Event
    window.cX.callQueue.push([
      'sendPageViewEvent',
      {
        location: window.location.href,
        referrer: document.referrer,
      },
    ]);

    window.cX.callQueue = sortQueue(window.cX.callQueue);
  }
  return null;
};

if (WAIT_FOR) {
  // reload for SPA
  document.addEventListener(WAIT_FOR, init);
  log(`waiting for ${WAIT_FOR} to initialize`);
  // tell referer async loading is ready
  document.dispatchEvent(new CustomEvent('cxLoaded'));
  log(`dispatch cxLoaded event`);
} else {
  init();
}
