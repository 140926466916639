import getCachedSegments from '../helpers/getCachedSegments';
import log from '../helpers/logger';

/**
 * handles string and objects containing SSP configuration
 * for either client or server user syncing
 */

class UserSync {
  constructor(ssps) {
    this.ssps = ssps;
  }
  static _getType = ssp => {
    let type;
    if (typeof ssp === 'string') {
      type = 's2s';
    } else if (typeof ssp === 'object' && ssp.type) {
      type = ssp.type;
    }

    return type;
  };
  sendRequests = () => {
    // eslint-disable-next-line no-invalid-this
    this.ssps.forEach(ssp => {
      if (UserSync._getType(ssp) === 's2s') {
        window.cX.callQueue.push(['sync', ssp]);
        log(`add usersync for ${ssp.partner ? ssp.partner : ssp} to queue`);
      } else if (ssp.partner) {
        const segments = getCachedSegments();

        if (ssp.partner === 'appnexus') {
          if (segments) {
            const body = document.getElementsByTagName('body')[0];
            const el = document.createElement('img');
            const prefix = 'cxsegment_';
            const keyvalues = [];
            keyvalues.push(`add_Code=${prefix}${segments.join(`,${prefix}`)}`);
            keyvalues.push(`member=${ssp.customerId}`);
            el.setAttribute('src', `//ib.adnxs.com/seg?${keyvalues.join('&')}`);
            el.setAttribute('width', '1');
            el.setAttribute('height', '1');
            body.appendChild(el);
            log(`execute usersync for ${ssp.partner}`);
          }
        } else {
          // for future ssps
        }
      }
    });

    return null;
  };
}

export default UserSync;
